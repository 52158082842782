import React from "react";
import  {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Axios from "axios";
import {ErrTemplates} from "../err_templates/errTemplates";
import InputMask from 'react-input-mask';
import mobileImg from '../assets/img/mobile_img.png'
import desktopImg from '../assets/img/desktop_img_min.png'
import helperImg from '../assets/img/helper.png'
import mainCSS from '../assets/main.css'


const CardActivatePage = () => {

    const [data, setData] = useState([])


    function changeHandler(e){
        const new_data = {...data}
        new_data[e.target.id] = e.target.value
        setData(new_data)
        new_data['error']=null
        new_data['errorClass']=null
        console.log(new_data)
    }

    function responseHandler(e){
        const resp_data = {...data}
        resp_data["resp"] = e
        setData(resp_data)
        console.log(resp_data)
    }

    function onclick(){
        let error = document.getElementsByClassName('error-msg')
        error.textContent = ''
    }

    function catchError(e){
        const err_data = {...data}
        err_data["error"] = ErrTemplates(e)
        setData(err_data)
        err_data['errorClass']='error'
        console.log(data.EAN)
    }

    function submit(e){
        const url = process.env.REACT_APP_API_URL+"/api/v1/cardadd/"
        e.preventDefault()
        if(!data.EAN){
            catchError('E111')
            return null
        }
        const ean = data.EAN.replace(/\s/g, '');
        data.EAN = ean
        if(ean.replace(/_/g, '').length < 13){
            catchError('E111')
            return null
        }
        Axios.post(url,{
            EAN:ean,
        }, {
            headers: { Authorization: process.env.REACT_APP_API_TOKEN }
        })
            .then(res=>{
                if(res.data.status===false){
                    catchError(res.data.err_code)
                }else{responseHandler(res.data.status)}
            })
            .catch(error=>{
                catchError("E004")
            }, )
    }
    const navigate = useNavigate()

    useEffect(() => {
            if(data.resp){
                navigate('/add', {state : {'EAN':data.EAN}})
            }

        }
    )

    return(
        <main>
            <div className="container">
                <div className="mainpage">
                    <div className="left">
                        <h1>Сервис распределения пластиковых карт «СУП» по сотрудникам</h1>
                        <form onSubmit={(e) => submit(e)} className="form">
                            <div className="form-title">Введите EAN код вашей карты «СУП»</div>
                            <div className="helper-box">
                                <div className="helper-wrapper">
                                    <div className="help-left">
                                        <img src={helperImg}/>
                                    </div>
                                    <div className="help-right">
                                        <h1>EAN — это 13-значный</h1>
                                        <p>номер, указанный под штрих-кодом
                                            на оборотной стороне вашей карты</p>
                                    </div>
                                </div>
                            </div>
                            <InputMask
                                id="EAN"
                                mask="9 9 9 9 9 9 9 9 9 9 9 9 9"
                                maskChar="_"
                                onChange={(e) => changeHandler(e)}
                                className={data.errorClass}
                            >
                                {(inputProps) => <input {...inputProps} type="text" placeholder="_ _ _ _ _ _ _ _ _ _ _ _ _" />}
                            </InputMask>

                            <button type="submit" className="form-btn">Привязать карту</button>
                            <div className="error-text">{data.error}</div>
                        </form>
                    </div>
                    <div className="right">
                        <img src={desktopImg} alt=""/>
                    </div>
                </div>
            </div>
            <div className="mobile-img">
                <img src={mobileImg} alt=""/>
            </div>
        </main>

    )
}

export default CardActivatePage;