import React from "react";

const NotFoundPage = () => {

    return(

        <div className="main-context">
            Страница не найдена
        </div>

    )

}

export  {NotFoundPage};