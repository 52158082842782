import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Axios from "axios";
import {ErrTemplates, ErrTemplateLink} from '../err_templates/errTemplates'
import mobileImg from '../assets/img/mobile_img.png'
import desktopImg from '../assets/img/desktop_img_min.png'
import mainCSS from '../assets/main.css'
import '../assets/img/favicon/apple-touch-icon-114x114.png'
import '../assets/img/favicon/apple-touch-icon-72x72.png'
import '../assets/img/favicon/apple-touch-icon.png'

const AuthPage = () => {

    const [data, setData] = useState([""]);
    const externalState = useLocation().state
    data.loading = "none"


    function changeHandler(e){
        const new_data = {...data}
        new_data[e.target.id] = e.target.value
        setData(new_data)
        new_data['error']=null
        new_data['errorClass']=null
        new_data['start'] = null
        new_data['end'] = null
        new_data['link'] = null
    }

    function responseHandler(e){
        const resp_data = {...data}
        resp_data["resp"] = e
        setData(resp_data)

    }


    function catchError(e){
        const err_data = {...data}
        if(e==="E007"){
            err_data['start'] = ErrTemplateLink().start
            err_data['end'] = ErrTemplateLink().end
            err_data['link'] = ErrTemplateLink().link
        }else{err_data["error"] = ErrTemplates(e)}

        err_data["errorClass"] = "error"
        setData(err_data)

    }

    function submit(e){
        const url = process.env.REACT_APP_API_URL+"/api/v1/auth/"
        e.preventDefault()
        const processedMail = data.mail.includes('@') ? data.mail.split('@')[0] : data.mail;
        Axios.post(url,{
            mail:processedMail.toLowerCase(),
            code:data.code,
            EAN:externalState.EAN,
        }, {
            headers: { Authorization: process.env.REACT_APP_API_TOKEN }
        })
            .then(res=>{
                if(res.data.status===false){
                    console.log(res.data.err_code)
                    catchError(res.data.err_code)
                }else{responseHandler(res.data.status)}
            })
            .catch(error=>{
                catchError("E004")
            }, )
    }
    const navigate = useNavigate()

    useEffect(() => {
            if(data.resp){
                navigate('/success', {state : {'code':data.code, 'EAN':externalState.EAN}})
            }
        }
    )

    return(
        <main>
            <link rel="stylesheet" href={mainCSS}/>
            <div className="container">
                <div className="mainpage">
                    <div className="left">
                        <h1>Сервис распределения пластиковых карт «СУП» по сотрудникам</h1>
                        <form onSubmit={e=>submit(e)} className="form">
                            <div className="form-title">Введите ваши личные учетные данные</div>
                            <input onChange={e=>changeHandler(e)} type="text" id="mail" placeholder="Логин корп. почты (всё, что до @)"
                                   className={data.errorClass}/>
                                <input onChange={e=>changeHandler(e)} type="text" id="code" placeholder="Табельный номер сотрудника"
                                       className={data.errorClass}/>
                                    <button type="submit" className="form-btn">Проверить данные</button>
                                    <div className="error-text">{data.error}</div>
                                    <a className="error-text">{data.start}<br/><a className="error-text"
                                                                                  href="https://meal.gift-cards.ru/activation"><b>{data.link}</b><br/></a>{data.end}</a>
                        </form>
                    </div>
                    <div className="right">
                        <img src={desktopImg} alt=""/>
                    </div>
                </div>
            </div>
            <div className="mobile-img">
                <img src={mobileImg} alt=""/>
            </div>
        </main>
    )
}

export default AuthPage;