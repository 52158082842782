import React from "react";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

import mobileImg from '../assets/img/mobile_img.png'
import desktopImg from '../assets/img/desktop_img_min.png'

const SuccessPage = () => {
    const externalState = useLocation().state

    const navigate = useNavigate()

    function locate(){
        window.location.replace('https://meal.gift-cards.ru/activation')
    }
    return(
        <main>
            <div className="container">
                <div className="mainpage">
                    <div className="left">
                        <h1>Сервис распределения пластиковых карт «СУП» по сотрудникам</h1>
                        <div className="form">
                            <div className="form-title">Поздравляем!<br></br> Вы всё сделали верно, ваша карта «СУП»
                                успешно
                                привязана к вашему табельному номеру сотрудника.
                            </div>
                            <div className="form-text">
                                Проверьте данные ниже и активируйте карту.<br/>Если произошла ошибка, и данные
                                не&nbsp;верны, обратитесь в отдел персонала.
                            </div>
                            <div className="form-data">
                                Табельный номер: {externalState.code}<br/>
                                EAN карты: {externalState.EAN}
                            </div>

                            <button className="form-btn"
                                    onClick={locate}>Активировать карту
                            </button>
                            <div className="form-text">
                                Пополнение карт производится работодателем по вторникам и пятницам.<br/>Проверить баланс вы можете <a className="link-balance" href="https://meal.gift-cards.ru/balance">здесь</a>.
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <img src={desktopImg} alt=""/>
                    </div>
                </div>
            </div>
            <div className="mobile-img">
                <img src={mobileImg} alt=""/>
            </div>
        </main>

    )

}

export  {SuccessPage};