import * as React from "react";
import {Outlet} from "react-router-dom"; // with import

import bs from "../../assets/libs/bootstrap4/css/bootstrap-grid.min.css"
import favicon from "../../assets/img/favicon/favicon.ico"
import appleTouchFav72 from "../../assets/img/favicon/apple-touch-icon-72x72.png"
import appleTouchFav from "../../assets/img/favicon/apple-touch-icon.png"
import appleTouchFav114 from "../../assets/img/favicon/apple-touch-icon-114x114.png"
import minipicture from "../../assets/minipicture.png"

export default function Header() {
    return (
        <>
        <header>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta name="theme-color" content="#000000"/>
            <meta
                name="Сервис распределения карт"
                content="Сервис распределения карт «СУП» по сотрудникам"
            />
            <meta property="og:image" content="%PUBLIC_URL%/minipicture.png"/>
            <meta property="og:description" content="Сервис распределения карт «СУП» по сотрудникам"/>
                <title>Сервис распределения карт «СУП» по сотрудникам</title>
                <meta name="description" content=""/>
                     <meta property="og:image" content={minipicture}/>
                    <link rel="shortcut icon" href={favicon} type="image/x-icon"/>
                    <link rel="apple-touch-icon" href={appleTouchFav}/>
                    <link rel="apple-touch-icon" sizes="72x72" href={appleTouchFav72}/>
                    <link rel="apple-touch-icon" sizes="114x114"
                          href={appleTouchFav114}/>
                    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                    <meta name="viewport"
                          content="width=device-width, initial-scale=1, maximum-scale=1"/>
                    <link rel="stylesheet" href={bs}/>
                    <link rel="stylesheet" href="../assets/libs/animate/animate.css"/>

                    <link rel="preconnect" href="https://fonts.googleapis.com"/>
                    <link rel="preconnect" href="https://fonts.gstatic.com"
                                                crossOrigin/>
                    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
                          rel="stylesheet"/>
                    <link rel="stylesheet" href="../assets/main.css"/>
                    <script src="../assets/libs/modernizr/modernizr.js"></script>
        </header>


        <Outlet/>


        </>

    );
}