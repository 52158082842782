import { Route, Routes} from "react-router-dom"
// We will create these two pages in a moment
import Header from './components/Header/Header';
import CardActivatePage from "./pages/CardActivatePage"
import AuthPage from "./pages/AuthPage";
import {SuccessPage} from "./pages/SuccessPage"
import {NotFoundPage} from "./pages/NotFoundPage";
import {OnlyHome} from "./hoc/OnlyHome";


function App(){

  return (
    <>
      <Routes>
          <Route path="/" element={<Header/>}>
              <Route path="/" element={<CardActivatePage/>}/>
                <Route path="add" element={
                    <OnlyHome>
                        <AuthPage/>
                    </OnlyHome>
                }/>
                <Route path="success" element={
                    <OnlyHome>
                        <SuccessPage/>
                    </OnlyHome>
                }/>
                  <Route path='*' element={
                      <OnlyHome>
                      <NotFoundPage/>
                      </OnlyHome>
                  }/>
          </Route>
      </Routes>
    </>
  )
}

export default App;

