import {useLocation, Navigate} from "react-router-dom";

const OnlyHome = ({children}) => {
    const location = useLocation()

    if(location?.state){
        return children

    }
    return <Navigate to='/'></Navigate>

}

export {OnlyHome}